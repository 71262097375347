"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault")["default"];

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _az_AZ = _interopRequireDefault(require("../../date-picker/locale/az_AZ"));

var _default = _az_AZ["default"];
exports["default"] = _default;